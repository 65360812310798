<template>
	<nav
		class="gateway-bar"
		:class="{ dark: dark }"
	>
		<NuxtLink
			v-for="(link, idx) in links"
			:to="link.to || link.href"
			:key="`${link.to || link.href}-${idx}`"
			:class="{ active: link.active }"
		>
			<TnChip
				class="hidden-desktop"
				:color="dark ? 'dark' : 'grey'"
				:text="link.text"
				:active="link.active"
			/>
			<span class="hidden-mobile gateway-text">{{ link.text }}</span>
		</NuxtLink>
	</nav>
</template>

<script>
import LinkViewModel from "../../../../helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
	name: "GatewayLinksBar",

	props: {
		component: { type: Object },
		dark: { type: Boolean, default: false },
	},

	computed: {
		isFrontPage() {
			return useRoute().path.endsWith("privat/") || useRoute().path.endsWith("bedrift/");
		},
		links() {
			if (this.isFrontPage) {
				return this.component.content.link.map((link) => ({ ...LinkViewModel(link), active: false }));
			} else {
				return this.component.content.link.reduce((acc, curr) => {
					const mappedLink = LinkViewModel(curr);
					const currentLinkUrl = mappedLink?.to || mappedLink?.href;
					const currentLinkUrlMatches = currentLinkUrl.includes(useRoute().path);
					const bestSoFar = acc.find((item) => item.active);
					const currentHasShortestLink = currentLinkUrl?.length < (bestSoFar?.href || bestSoFar?.to)?.length;
					const currentIsNewActive = currentLinkUrlMatches && (!bestSoFar || currentHasShortestLink);
					return [
						...acc.map((item) => ({ ...item, active: !currentIsNewActive && item.active })),
						...(mappedLink.text && currentLinkUrl ? [{ ...mappedLink, active: currentIsNewActive }] : []),
					];
				}, []);
			}
		},
	},
});
</script>

<style lang="scss" scoped>
.ecommerce-product-page {
	.gateway-bar {
		border-top: none;

		a {
			&.active {
				span {
					border-bottom: 1px solid $color-cta-active;
					font-weight: bold;
				}
			}

			span {
				padding-bottom: $spacing-s;

				@include breakpoint(mobile) {
					padding-bottom: 2px;
				}
			}
		}
	}
}

.gateway-bar {
	text-align: center;
	border-top: 1px solid $color-neutrals-200-tint;
	height: 57px;

	@include font-text-m;

	.gateway-text {
		padding-bottom: $spacing-s;
	}

	&.dark {
		a {
			color: $color-cta-dark-default;

			&:hover,
			&:focus {
				.gateway-text {
					color: $color-cta-dark-hover;
					border-bottom: 1px solid $color-cta-dark-hover;
				}
			}

			&.active:not(:hover) {
				.gateway-text {
					border-bottom: 1px solid $color-cta-dark-active;
				}
			}
		}
	}

	a {
		display: inline-block;
		padding: $spacing-m;
		color: $color-neutrals-black;
		text-decoration: none;

		@media (min-width: $size-screen-s) {
			&:hover,
			&:focus {
				.gateway-text {
					color: $color-cta-hover;
					border-bottom: 1px solid $color-cta-hover;
				}
			}

			&.active {
				font-weight: bold;
			}

			&.active:not(:hover) {
				.gateway-text {
					border-bottom: 1px solid $color-cta-active;
				}
			}
		}
	}

	@media (max-width: $size-screen-xs) {
		height: auto;
		overflow-x: scroll;
		white-space: nowrap;
		padding: 0 $spacing-s;

		@include font-text-xs;

		a {
			padding: 10px $spacing-xs;

			.gateway-text {
				padding: 2px 10px;
				border: 1px solid $color-neutrals-200-tint;
				border-radius: 3px;
			}
		}
	}

	@include breakpoint(tablet) {
		@include font-text-xs;
	}
}
</style>
